.BBPTransaction {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.BBPTransaction .BBPTHead {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  column-gap: 16px;
}

.BBPTransaction .BBPTHead .BBPTHBtn {
  display: flex;
  flex-wrap: wrap;
  padding-left: 24px;
  flex: 1;
  justify-content: flex-end;
  position: relative;
}

.BBPTransaction .BBPTHead .BBPTHBtn .BBPTHBD {
  margin-left: 16px;
  font-weight: normal;
  font-size: 14px;
  letter-spacing: 0em;
  line-height: normal;
  text-align: left;
  color: #fff;
  border-radius: 10px;
  background-color: #175783;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  padding: 7px 15px;
  text-decoration: none;
  border: 2px solid #175783;
}

.BBPTransaction .BBPTHead .BBPTHBtn .BBPTHBD[disabled] {
  opacity: 0.5;
}

.BBPTransaction .BBPTBody {
  flex: 1;
  padding-top: 16px;
  overflow: auto;
}

.BBPTransaction .BBPTHead .BBPTHBtn .BBPTHIcon {
  display: flex;
  margin-right: 15px;
}

.BBPTransaction .BBPTHead .BBPTHBtn .BBPTHIcon img {
  width: 100px;
  height: auto;
}

.BBPTransaction .BBPTHead .BBPTHBtn .BBPTHDrop {
  display: flex;
  margin-right: 16px;
}

@media only screen and (min-width: 1200px) and (max-width: 1367px) {
}

@media only screen and (min-width: 1024px) and (max-width: 1200px) {
  .BBPTransaction .BBPTHead {
    display: block;
  }

  .BBPTransaction .BBPTHead .BBPTHBtn {
    justify-content: flex-start;
    padding-left: 0;
    padding-top: 15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .BBPTransaction .BBPTHead {
    display: block;
  }

  .BBPTransaction .BBPTHead .BBPTHBtn {
    padding-left: 0;
    padding-top: 15px;
  }

  .BBPTransaction .BBPTHead .BBPTHBtn .BBPTHIcon {
    flex: 1;
  }
}

.calenderIcon {
  width: 24px;
  height: 24px;
  cursor: pointer;
  border: none;
  background-color: transparent;
  display: none;
  border: 1px solid;
  /* position: absolute;
  z-index: 9999;
  position: fixed;
  top: 50px; 
  left: 0;
  z-index: 9999; */
}
.react-datepicker-popper {
  position: absolute; /* Set position to absolute */
  top: 0; /* Position at the top of the parent container */
  left: 0; /* Position at the left of the parent container */
  z-index: 9999; /* Ensure it's on top of other content */
}

.uploadFileCont{
  background: #FFFFFF;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  border-radius: 4px ;
  box-sizing: border-box;
  padding: 0px 12px;
}

.uploadFileCont img{
  height: 20px;
}

.labelContFu{
  display: flex;
  align-items: center;
  gap: 10px;
}
.labelContFu p{
  font-size: 14px;
}


.remaksDPDW{
  border: none;
  font-size: 11px;
  width: 70%;
}

.remaksDPDW:focus{
  outline: none;
}


.CCDetailGrid {
  /* border: 1px solid; */
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: 10px;
  /* Adds space between the grid items */
  padding: 1rem 2rem;
  /* Adds padding around the grid */
}

.CCDetailGridItem {
  /* border: 1px solid; */
  display: flex;
  justify-content: center;
  /* text-align: center; */
  flex-direction: column;
  /* align-items: center; */
  gap: 5px;
  /* padding: 0px 16px; */
  margin: 10px 0px;
}

.CCDetailGridItem p {
  line-height: normal;
  margin: 0px 0px;
  font-weight: 500;
  font-size: 12px;
  /* border: 1px solid; */

}

#ccdFont {
  font-weight: 300;
}


@media only screen and (min-width: 600px) and (max-width: 900px) ,
only screen and (min-width: 768px) and (max-width: 1024px),
only screen and (min-width: 820px) and (max-width: 1180px),
only screen and (min-width: 834px) and (max-width: 1194px){
  .BBPTransaction .BBPTHead {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    flex-wrap: wrap;
    align-items: center;
    column-gap: 16px;
    /* border: 1px solid; */
  }

  .BBPTransaction .BBPTHBtn {
    /* border: 1px solid red; */
    grid-column: 1 / span 3;
    display: flex;
    align-items: center;
    /* margin :0px !important; */
    padding: 10px 0px !important;
    justify-content:flex-end !important;
  }

  .BBPdateContainer{
    display: flex;
    align-items: center;
  }

  .uploadFileCont{
    /* border: 1px solid; */
    display: flex;
    justify-content: center;
    /* width: 8rem; */
    max-width: 9rem;
  }
  

}



.uploadFileCont {
  background: #FFFFFF;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  border-radius: 4px;
  box-sizing: border-box;
  padding: 0px 12px;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  /* border: 1px solid; */
  /* width: 12rem; */
  max-width: 16rem;
}

.uploadFileCont img {
  height: 20px;
}

.uploadFileCont p {
  margin: 0px;
}