.txnDetailDialog {
  display: flex;
  justify-content: space-between;
  padding: 4px 16px;
  background-color: rgb(202, 203, 203);
}

.txnDetailDialog p {
  margin: 0px 0px;
  font-weight: 500;
  font-size: 14px;
}

.BBPCPLFORMItem {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.BBPCPLFORMItem label {
  font-size: 14px;
}

.BBPCPLFORMRItem {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.BBPCPLFORMItems {
  display: flex;
  align-items: center;
}

.BBPCPLFSelectItem {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.BBPCPLFSelectItem label {
  font-size: 12px;
}

.BBPMFormCont {
  height: 90%;
  border: 1px solid;
  /* display: flex; */
  /* flex-direction: column; */
  /* justify-content: space-between; */
  padding: 0.5rem 1rem;
  box-sizing: border-box;
}

.BBPCPLFORMBtn {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
}

.BBPPayoutBtnCont {
  display: flex;
  justify-content: center;
  /* border: 1px solid; */
  gap: 2rem;
  /* align-items: end; */
  margin-top: 5rem;
}

.BBPSPFGrid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  /* border: 1px solid; */
  padding: 0rem 1.5rem;
}

.merchantDialogCont {
  display: flex;
  justify-content: flex-end;
  height: 2rem;
  margin: 0px;
}
.merchantDialogCont p {
  margin: 0px 0px;
}


.BBPMInfo {
  border: 1px solid;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  font-size: 14px;
  padding: 0.5rem;
  box-sizing: border-box;
  border-radius: 8px;
}

.BBPMInfo p {
  margin: 0px 0px;
}
.button-container {
  grid-column: span 2; /* Make the button span both columns */
  display: flex;
  justify-content: center; /* Center the button horizontally */
  margin-top: 10px; /* Optional: add some space above the button */
}


.button-container button{
  background-color: #175783;
  color: #fff;
  transition: none;
}
.button-container Button:hover {
  background-color: #175783; /* Keep the original background color */
  color: #fff; /* Keep the original text color */
  border: inherit; /* Keep the original border color */
}


.BBPMInfo {
  border: 1px solid;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  font-size: 14px;
  padding: 0.5rem;
  box-sizing: border-box;
  border-radius: 8px;
}

.BBPMInfo p {
  margin: 0px 0px;
}


.BBPAlertDialog {
    padding: 30px;
}

.BBPAlertDialog .BBPADHead {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
}

.BBPAlertDialog .BBPADHead .BBPSDAIcon {
    display: flex;
    font-size: 50px;
}
 
.BBPAlertDialog .BBPADHead .BBPSDATitle {
    font-weight: 600;
    font-size: 16px;
    letter-spacing: 0.07em;
    text-align: left;
    color: #2a2a2a;
    padding-left: 15px;
}

.BBPAlertDialog .BBPSDASTitle {
    text-align: center;
    font-weight: normal;
    font-size: 14px;
    letter-spacing: 0.09em;
    color: #2a2a2a;
}

.BBPAlertDialog .BBPADBtn {
    display: flex;
    justify-content: center; 
    padding-top: 20px;
}

.BBPAlertDialog .BBPADBtn button { 
    margin: 0 15px;
}
 
.BBPAlertDialog .BBPADBtn button.BBPADBDelete {
    background-color: #ee2505;
}
 
@media only screen and (max-width:767px) { 

    .BBPAlertDialog {
        padding: 15px;
    }

    .BBPAlertDialog .BBPADHead { 
        margin-bottom: 20px;
    }

    .BBPAlertDialog .BBPSDASTitle { 
        font-size: 16px; 
    }

    .BBPAlertDialog .BBPADHead .BBPSDAIcon {
        font-size: 40px;
    }

    .BBPAlertDialog .BBPADHead .BBPSDATitle { 
        font-size: 16px; 
        padding-left: 20px;
    }
 
}