.BBPSelectOption .SelectOptionLabel {
    font-weight: normal;
    font-size: 14px;
    letter-spacing: 0em;
    line-height: normal;
    text-align: left;
    color: #fff;
    border-radius: 10px;
    background-color: #175783;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
    padding: 7px 15px;
    padding-right: 25px;
    text-decoration: none;
    border: 2px solid #175783;
    min-width: 125px;
    justify-content: space-between;
}

.BBPSelectOption .SelectOptionLabel::after {
    content: '';
    position: absolute;
    width: 8px;
    height: 8px;
    top: 10px;
    right: 10px;
    border-left: 2px solid #ffffff;
    border-bottom: 2px solid #ffffff;
    transform: rotate(-45deg);
}
  
.BBPSelectOption .SelectOptionLabel:hover { 
    background-color: #175783;
} 
  
.BBPSOptions {
    z-index: 99;
}

.BBPSOptions .BBPSOList li {
    text-transform: capitalize;
    font-size: 14px;
    font-weight: 500;
    color: #000;
}

.BBPSOptions .BBPSOList li.BBPSPSelected {
    background-color: rgb(0 0 0 / 6%);
}

.BBPTextField input {
    font-weight: normal;
    font-size: 14px;
    letter-spacing: 0em;
    line-height: 28px;
    text-align: left;
    color: #171725;
}

.BBPImg {
    display: flex;
}

label.BBPLabel {
    font-weight: normal;
    font-size: 14px;
    letter-spacing: 0em;
    line-height: normal;
    text-align: left;
    color: #171725;
    display: block;
    margin-bottom: 10px;
}
 
label.BBPLabel span {
    color: #ee2505;
}

.BBPImg .BBPIUpload .BBPIULabel {
    padding: 7.5px 25px;
    font-size: 14px;
    line-height: 21px;
    text-transform: inherit;
    font-weight: 500;
    color: #171725;
    border: 1px solid rgba(0, 0, 0, 0.23);
    justify-content: flex-start;
    position: relative;
    background-color: unset;
    box-shadow: unset;
}
 
.BBPImg .BBPIUpload .BBPIULabel span.MuiButton-endIcon svg {
    font-size: 30px;
}
 
.BBPImg .BBPIPreview {
    margin-left: 10px;
    width: 70px;
    height: 47px;
    border: 1px solid #ddd;
}

.BBPImg .BBPIPreview img { 
    width: 100%; 
    height: 100%; 
    object-fit: contain;
    object-position: center;
}

.BBPSError {
    font-weight: 400;
    font-size: 0.75rem;
    line-height: 1.66;
    letter-spacing: 0.03333em;
    text-align: left;
    margin-top: 3px;
    margin-right: 14px;
    margin-bottom: 0;
    margin-left: 14px;
    font-family: "Roboto","Helvetica","Arial",sans-serif;
    color: #d32f2f;
}

.BBPApiAutocomplete .BBPAACInner .BBPAACILabel.BBPAACIError,
.BBPImg .BBPIUpload .BBPIULabel.BBPIULError,
.BBPSelectOption .SelectOptionLabel.SOLError {
    border-color: #d32f2f;
}
 
.BBPAFDPopper .BBPAFDPaper ul li {
    text-transform: capitalize;
}
 
.BBPAAPopper .BBPAAPInner {
    background-color: #fff;
    border-radius: 4px;
    box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12);
}

.BBPAAPopper .BBPAAPInner .BBPAAPILoader{
    padding: 8.5px 16px;
}

.BBPRFSlider {
    display: flex;
    padding: 0 15px;
}
 
.BBPApiAutocomplete .BBPAACInner .BBPAACILabel {
    padding: 13px 14px;
    padding-right: 40px;
    font-size: 14px;
    line-height: 19px;
    text-transform: inherit;
    font-weight: 500;
    color: #171725;
    border: 1px solid rgba(0, 0, 0, 0.23);
    justify-content: flex-start;
    position: relative;
}

.BBPApiAutocomplete .BBPAACInner .BBPAACILabel:hover {
    border-color: rgba(0, 0, 0, 0.87);
}

.BBPApiAutocomplete .BBPAACInner .BBPAACILabel.open {
    border-color: #1976d2;
}

.BBPApiAutocomplete .BBPAACInner .BBPAACILabel::after {
    content: '';
    position: absolute;
    width: 10px;
    height: 10px;
    top: 15px;
    right: 15px;
    border-left: 2px solid #171725;
    border-bottom: 2px solid #171725;
    transform: rotate(-45deg);
} 

.BBPAAPSearch .BBPAAInput {
    display: flex;
    padding: 10px 10px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.23);
}

.BBPAAPSearch .BBPAAInput input {
    padding: 10px 10px;
    padding-right: 40px;
    font-size: 14px;
    line-height: 20px;
    text-transform: inherit;
    font-weight: 500;
    color: #171725;
    border: 1px solid rgba(0, 0, 0, 0.23);
    justify-content: flex-start;
    position: relative;
    width: 100%;
    border-radius: 4px;
    outline: 0;
}
 
.BBPAAPSearch .BBPAAILoader {
    padding: 8.5px 16px;
}

.BBPACFNew .BBPACFNMain .BBPACFNField input {
    font-size: 14px;
    padding: 4.5px;
    text-transform: capitalize;
}

.BBPACFNPopper .BBPACFNListbox {
    text-transform: capitalize;
}   