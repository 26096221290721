.BBPDashboardPage {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: auto;
}

.BBPDashboardPage .BBPDPTitle {
  font-size: 16px;
  font-weight: 600;
  color: #000;
  line-height: normal;
  padding-bottom: 16px;
}

.BBPDashboardPage .BBPDPCharts {
  display: flex;
  flex-wrap: wrap;
  column-gap: 15px;
  row-gap: 15px;
}

.BBPAreaChart {
  width: calc(25% - 12.5px);
  border-radius: 6px;
  background: #fff;
  box-shadow: 0px 1px 4px rgba(21, 34, 50, 0.08);
  padding: 20px;
}

.BBPAreaChart .BBPACMTitle {
  font-size: 14px;
  line-height: normal;
  color: #7e84a3;
  margin-bottom: 15px;
}

.BBPAreaChart .BBPACInfo {
  display: flex;
}

.BBPAreaChart .BBPACInfo .BBPACIDet {
  width: 55%;
  align-self: flex-end;
}

.BBPAreaChart .BBPACInfo .BBPACIDet .BBPACIDTitle {
  color: #131523;
  font-size: 22px;
  font-weight: 600;
  margin-bottom: 5px;
}

.BBPAreaChart .BBPACInfo .BBPACIDet .BBPACIDSubTitle {
  /* display: flex; */
  align-items: center;
}

.BBPAreaChart .BBPACInfo .BBPACIDet .BBPACIDSubTitle .BBPACIDPer {
  font-size: 16px;
  font-weight: 600;
  display: flex;
  align-items: center;
  line-height: normal;
}

.BBPAreaChart .BBPACInfo .BBPACIDet .BBPACIDSubTitle .BBPACIDPTitle {
  font-size: 12px;
  color: #7e84a3;
  line-height: normal;
  padding-left: 3px;
}

.BBPAreaChart .BBPACInfo .BBPACIChart {
  width: 45%;
}

.BBPDashboardPage .BBPDPLineChart {
  margin: 15px 0;
  padding: 20px;
  border-radius: 6px;
  background: #fff;
  box-shadow: 0px 1px 4px rgba(21, 34, 50, 0.08);
}

.BBPDashboardPage .BBPDPLineChart .BBPDPlCHead {
  display: flex;
  align-items: center;
  padding-bottom: 15px;
}

.BBPDashboardPage .BBPDPLineChart .BBPDPlCHead .BBPDPlCHTitle {
  flex: 1;
  font-size: 16px;
  font-weight: 600;
  color: #131523;
}

.BBPDashboardPage .BBPDPLineChart .BBPDPlCChart .recharts-cartesian-axis-tick {
  font-size: 12px;
  font-weight: 500;
}

.BBPDashboardPage
  .BBPDPLineChart
  .BBPDPlCChart
  .recharts-cartesian-axis-tick
  text.recharts-text {
  fill: #7e84a3;
}

.BBPDashboardPage
  .BBPDPLineChart
  .BBPDPlCChart
  .recharts-cartesian-axis-tick
  line.recharts-cartesian-axis-tick-line,
.BBPDashboardPage
  .BBPDPLineChart
  .BBPDPlCChart
  .recharts-cartesian-axis
  line.recharts-cartesian-axis-line {
  display: none;
}

.BBPDashboardPage
  .BBPDPLineChart
  .BBPDPlCChart
  .recharts-surface
  .recharts-tooltip-cursor {
  stroke: #5891ff;
  transition: all ease 0.1s;
}

.BBPDashboardPage .BBPDPInfoBoxMain {
  display: flex;
  flex-wrap: wrap;
  column-gap: 15px;
  row-gap: 15px;
  margin-top: 15px;
}

.BBPDPInfoBox {
  width: calc(50% - 12.5px);
  border-radius: 6px;
  background: #fff;
  box-shadow: 0px 1px 4px rgba(21, 34, 50, 0.08);
  padding: 20px;
}

.BBPDPInfoBox .BBPDPIB {
  display: flex;
  flex-wrap: wrap;
  column-gap: 15px;
  row-gap: 15px;
  margin: 0rem 0rem 1rem 0rem;
}

.BBPDPInfoBox .BBPDPIB .BBPDPIBS {
  width: calc(25% - 12px);
  border-radius: 6px;
  padding: 10px;
  /* border: 1px solid; */
  /* text-align: center; */
}

.BBPDPInfoBox .BBPDPIB .BBPDPIBS .BBPDPIBSTitle {
  font-size: 12px;
  font-weight: 500;
  line-height: normal;
  color: #2a2a2a;
  margin-bottom: 10px;
  height: 45%;
}

.BBPDPInfoBox .BBPDPIB .BBPDPIBS .BBPDPIBSVal {
  color: #131523;
  font-size: 12px;
  font-weight: 500;

  height: 45%;
}

.BBPDPIBSValImg {
  height: 3rem;
  width: 13rem;
  object-fit: contain;
}

.BBPCustomTooltip {
  background-color: #fff;
  border: 1px solid #ededed;
  padding: 5px;
  padding-bottom: 0;
  border-radius: 4px;
  min-width: 175px;
}

.BBPCustomTooltip .BBPCTitle {
  font-size: 14px;
  line-height: normal;
  font-weight: 600;
  color: #000;
  text-transform: capitalize;
}

.BBPCustomTooltip .BBPCList {
  font-size: 12px;
  line-height: normal;
  padding: 4px 0;
  color: #7e84a3;
}

.BBPCustomTooltip .BBPCList strong {
  color: #000;
  margin-right: 7px;
  font-weight: 500;
}

.BBPCustomTooltip .BBPCList span {
  display: inline-block;
  width: 7px;
  height: 7px;
  border-radius: 100%;
  margin-right: 7px;
}

.BBPDashboardPage
  .BBPDPLineChart
  .BBPDPlCChart
  .recharts-wrapper
  ul.recharts-default-legend {
  font-size: 16px;
  line-height: normal;
}

.BBPDashboardPage
  .BBPDPLineChart
  .BBPDPlCChart
  .recharts-wrapper
  ul.recharts-default-legend
  li.recharts-legend-item
  span.recharts-legend-item-text {
  color: #5a607f !important;
}

.BBPDashboardPage
  .BBPDPLineChart
  .BBPDPlCChart
  .recharts-wrapper
  ul.recharts-default-legend
  li.recharts-legend-item
  svg.recharts-surface {
  width: 8px;
  height: 8px;
}

.BBPDashboardPage .BBPDPBarCharts {
  display: flex;
  flex-wrap: wrap;
  column-gap: 15px;
  row-gap: 15px;
}

.BBPDPBarCharts .BBPBarChart {
  width: 100%;
  border-radius: 6px;
  background: #fff;
  box-shadow: 0px 1px 4px rgba(21, 34, 50, 0.08);
  padding: 20px;
}

.BBPDPBarCharts .BBPBarChart .BBPBCHead {
  display: flex;
  padding-bottom: 10px;
}

.BBPDPBarCharts .BBPBarChart .BBPBCHead .BBPBCHTitle {
  flex: 1;
  font-size: 16px;
  font-weight: 600;
  color: #131523;
}

.BBPDPBarCharts .BBPBarChart .BBPBCHead .BBPBCHBtn {
  display: flex;
}

.BBPDPBarCharts .BBPBarChart .BBPBCHead .BBPBCHBtn button {
  color: #5a607f;
  border-color: #d7dbec;
  text-transform: capitalize;
  font-size: 14px;
}

.BBPDPBarCharts .BBPBarChart .BBPBCChart {
  overflow: auto;
  height: 425px;
}

.BBPDPBarCharts .BBPBarChart .recharts-wrapper ul.recharts-default-legend {
  font-size: 16px;
  line-height: normal;
}

.BBPDPBarCharts
  .BBPBarChart
  .recharts-wrapper
  ul.recharts-default-legend
  li.recharts-legend-item
  span.recharts-legend-item-text {
  color: #5a607f !important;
}

.BBPDPBarCharts
  .BBPBarChart
  .recharts-wrapper
  ul.recharts-default-legend
  li.recharts-legend-item
  svg.recharts-surface {
  width: 8px;
  height: 8px;
}

.BBPDPBarCharts .BBPBarChart .recharts-cartesian-axis-tick {
  font-size: 12px;
  font-weight: 500;
}

.BBPDPBarCharts .BBPBarChart .recharts-cartesian-axis-tick text.recharts-text {
  fill: #7e84a3;
  text-transform: capitalize;
}

.BBPDPBarCharts
  .BBPBarChart
  .recharts-cartesian-axis-tick
  line.recharts-cartesian-axis-tick-line,
.BBPDPBarCharts
  .BBPBarChart
  .recharts-cartesian-axis
  line.recharts-cartesian-axis-line {
  display: none;
}

.BBPDPBarCharts .BBPBarChart .recharts-surface .recharts-tooltip-cursor {
  stroke: #5891ff;
  transition: all ease 0.1s;
  fill: transparent;
}

.BBPDashboardPage .BBPDPLineChart .BBPDPlCHead .BBPDPlCHFilter {
  display: flex;
}

.BBPDashboardPage .BBPDPLineChart .BBPDPlCHead .BBPDPlCHFilter button {
  line-height: normal;
  text-transform: capitalize;
  font-weight: 500;
  color: #fff;
  background-color: #175783;
  box-shadow: 0px 3px 6px rgb(0 0 0 / 16%);
}

.ContentHeading {
  display: flex;
  justify-content: space-between;
  margin: 0rem;
}

.ContentHeading p {
  margin-bottom: 0rem;
  margin: 0rem;
  margin-top: 0rem;
}
.dateHeadingContent {
  display: flex;
  gap: 0.5rem;
  padding-right: 0.5rem;
  align-items: center;
 
}

#blueP {
  color: #175783;
  margin: 0rem;
  cursor: pointer;
  /* border: 1px solid; */
}
#bluePRefresh {
  color: #175783;
  margin: 0rem;
  cursor: pointer;
  font-weight: 500;
}

.dateHeadingContent .calenderIcon {
  color: #175783;
  font-size: larger;
  cursor: pointer;
  /* display: none; */
}
.date-icon-container input[type="date"] {
  display: none;
}

/* Style the icon */
.calenderIcon {
  width: 20px;
  height: 20px;
  cursor: pointer;
  border: none;
  background-color: transparent;
  /* color: #175783; */
  /* position: relative; */
  /* background-color: #175783; */
  /* padding-right: 1px; */
  display: none;
  position: absolute;
  z-index: 1000;
}

.calenderIcon::-webkit-calendar-picker-indicator {
  filter: invert(1);
  background-color: transparent !important;
  color: transparent;
}
.calenderIcon::placeholder {
  color: transparent !important;
}
.imgDateIcon {
  width: 20px;
  height: 20px;
}
.calenderIcon ::-moz-focus-inner {
  border: none;
}
/* Style the label */
.date-label {
  margin-left: 8px; /* Adjust as needed */
  cursor: pointer;
}

.calenderDiv input[type="date"]:focus {
  outline: none;
  border-color: transparent;
}
.calenderDiv {
  color: #175783;
  display: flex;
  align-items: center;
}

.SuccessRatioContainer {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
  /* border: 1px solid; */
  align-items: center;
}

.SuccessRatioContainer p {
  margin: 0rem;
  /* margin-top: 0rem; */
  /* border: 1px solid; */
  /* height: 3rem; */
  height: 35px;
  background-color: #67548d;
  color: #fff;
  border-radius: 12px;
  padding: 0rem 1rem;
  display: flex;
  align-items: center;
  font-size: smaller;
}

.BBPSelectDiv {
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  width: 120px;
  position: relative;
}
.BBPSelectDiv .BBPSBInput {
  padding: 0px 0px 0px 15px;
  margin: 0;
  border: 0;
  display: block;
  font-family: Poppins;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  text-align: left;
  color: #5a607f;
  border-radius: 5px;
  position: relative;
  z-index: 1;
  background-color: transparent;
  outline: 0;
  width: 100%;
  height: 2rem;
}
.newBoxTitle {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 12px;
}
.newBoxValue {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.newBoxValue p {
  margin: 0rem;
  font-size: small;
  font-weight: 500;
  /* border: 1px solid; */
  line-height: normal;
  margin-bottom: 0px;
  margin-top: 5px;
}
.newBoxTitle p {
  margin: 0rem;
  font-size: 12px;
  font-weight: 500;
  /* border: 1px solid; */
  line-height: normal;
  margin-bottom: 0px;
}
.newBox {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: calc(25% - 12px);
  border-radius: 6px;
  padding: 10px;
}




@media (min-width: 600px) and (max-width: 1090px) {
.BBPDPInfoBox {
  /* border: 1px solid; */
  width:100%;
  border-radius: 6px;
  background: #fff;
  box-shadow: 0px 1px 4px rgba(21, 34, 50, 0.08);
  padding: 20px;
}

.ContentHeading p{
  font-size: 10px;
}
}
@media (max-width: 600px) {
  .BBPDPInfoBox {
    /* border: 1px solid; */
    width:100%;
    border-radius: 6px;
    background: #fff;
    box-shadow: 0px 1px 4px rgba(21, 34, 50, 0.08);
    padding: 20px;
  }
  .SuccessRatioContainer {
    display: flex;
    flex-direction: column;
    /* justify-content: space-between; */
    margin-bottom: 1rem;
    /* border: 1px solid; */
    /* align-items: center; */
  }
  .BBPDPIB{
    display: grid !important;
    grid-template-columns: repeat(2, 1fr) !important;
    gap: 5px;
    /* border: 1px solid; */
  }
  .newBox {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    border-radius: 6px;
    padding: 10px;
  }
  

}

.bankIcon{
  width: 100px;
  height: 2.5rem;
  object-fit: contain;
  /* border: 1px solid; */
}
.BBPDTSTextMultiple{
  /* border: 1px solid; */
  display: flex;
  flex-direction: column;
  gap: 5px;
}