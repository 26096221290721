.BBPRightSidePanel {
    width: calc(100% - 200px);
    padding-left: 16px;
}

.BBPRSPFull {
    width: 100%;
    padding-left: 0;
}

.BBPRightSidePanel .BBPRSPInner {
    width: 100%;
    height: 100%;
    border-radius: 20px;
    background-color: #fff;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
}

.BBPRightSidePanel.BBPRSPRBG .BBPRSPInner { 
    border-radius: 0;
    background-color: unset; 
    box-shadow: unset; 
} 